import {
    ADD_BOOKING,
    BOOKING_ERROR,
} from "../types";
import customAxios from "../../utils/customAxios";
import { setAlert } from "../alert";

export const submitBooking = async (requestBody) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await customAxios.post(
            "/api/v2/bookings/submit",
            requestBody,
            config
        );

        return res.data;
        // dispatch({
        //     type: ADD_BOOKING,
        //     payload: res.data,
        // });
        // dispatch(setAlert("Booking created", "success"));
    } catch (err) {
        // const errors = err.response.data.errors;
        // if (errors) {
        //     dispatch(
        //         setAlert(
        //             "Error. " + errors.codeName + " " + JSON.stringify(errors.keyValue),
        //             "danger"
        //         )
        //     );
        // }
        // dispatch({
        //     type: BOOKING_ERROR,
        //     payload: { msg: err.response.statusText, status: err.response.status },
        // });
        return err;
    }
};