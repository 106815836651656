/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getBookingsByTokenCartID } from "../../actions/admin/booking";
import { useEffect } from "react";
import { useState } from "react";
import visa_icon from "../../icons/visa.svg";
import master_card_icon from "../../icons/master-card.svg";
import jcb_icon from "../../icons/jcb.svg";
import american_ex_icon from "../../icons/american_ex.svg";
import LanguageCard from "../layout-new-theme/LanguageCard";
import { getLocationByAddress } from "../../actions/googleAPI";
import { useHistory } from "react-router-dom";
import { setLanguage } from "../../actions/admin/settings";
import logo_brand from "../../images/tlf-logo-brand.svg";
import {
  flywireUpdatePayment,
  flywireAddPayment,
  setDefaultPayment,
  deletePayment,
  flywireUpdatePaymentAuthUser
} from "../../actions/admin/flywire";
import logo_brand_name from "../../images/tlf-logo-brand-name.svg";
import selected_radio_button from "../../images/selected-radio.svg";
import un_selected_radio_button from "../../images/un-selected-radio.svg";
import three_dots from "../../images/three_dots.svg";
import red_radio_button from "../../images/selected-radio-red.svg";
import add_icon_gold from "../../images/add_icon_gold.svg";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch } from "react-redux";

const ChangePaymentMethod = () => {
  const dispatch = useDispatch();
  const {  i18n } = useTranslation();
  const { token } = useParams();
  const [authenticated, setAuthenticated] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [bookings, setBookings] = useState([]);
  const [cartId, setCartId] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const history = useHistory();

  const { t } = useTranslation();

  const callbackFlywire = async (result, ref) => {
    result.ref = ref;

    if (result.status === "success") {
      const updatePayment = await flywireUpdatePayment(result);

      setLoading(false);
      if (
        updatePayment.status === 200 &&
        updatePayment.data.status === "SUCCESS"
      ) {
        history.push(`/success-change-payment-method`);
      }
    }
  };

  const callbackFlywireAddPaymentMethod = async (result, ref) => {
    result.ref = ref;

    if (result.status === "success") {
      const updatePayment = await flywireAddPayment(result);

      setLoading(false);
      if (
        updatePayment.status === 200 &&
        updatePayment.data.status === "SUCCESS"
      ) {
        history.go();
      }
    }
  };

  const extractFirstAndLastName = async (fullName) => {
    // Split the full name into an array of strings
    const nameParts = fullName.trim().split(" ");

    // Extract the first name (first element of the array)
    const firstName = nameParts[0];

    // Extract the last name (last element of the array, if exists)
    const lastName =
      nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";

    // Return an object containing the first name and last name
    return {
      firstName: firstName,
      lastName: lastName ? lastName : firstName,
    };
  };

  const changePaymentMethodHandler = async () => {
    setLoading(true);

    const { firstName, lastName } = await extractFirstAndLastName(
      bookings[0].customer.name
    );
    const zipCodeCheck = await getLocationByAddress(
      bookings[0].customer.postal_code,
      bookings[0].customer.country_code
    );

    const config = {
      env: process.env.REACT_APP_FLYWIRE_ENV,
      recipientCode: process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,

      // >0 for Seed Payment and Optional Tokenization; 0 for Zero-Value Tokenization;
      amount: 0,
      //Mandatory tokenization before payment - "tokenization" or "optional_tokenization";
      recurringType: "tokenization",
      recurringTypeRecipientCodes: [
        process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,
        process.env.REACT_APP_FLYWIRE_JPT_RECIPIENT_CODE,
      ],

      // Specify the returnUrl or an onCompleteCallback handler
      // returnUrl: process.env.REACT_APP_URL + "/PaymentSuccess/"+ cart_id,

      // Other checkout parameters (e.g. pass payer info or set requestPayerInfo to true)
      firstName: firstName,
      lastName: lastName,
      email: bookings[0].customer.email,
      phone:
        bookings[0].customer.prefix_number + " " + bookings[0].customer.phone,
      address: bookings[0].customer.address,
      city: zipCodeCheck.address_components.filter(
        (item) =>
          item.types.includes("administrative_area_level_2") ||
          item.types.includes("locality")
      )[0].long_name,
      zip: bookings[0].customer.postal_code,
      country: bookings[0].customer.country_code,

      recipientFields: {
        booking_reference: `CB-${cartId}`,
        additional_information: null,
      },

      requestPayerInfo: false,
      requestRecipientInfo: false,

      // Control which tokenization options are available
      paymentOptionsConfig: {
        filters: {
          type: ["credit_card"],
        },
      },
      onCompleteCallback: function (args) {
        callbackFlywire(args, `CB-${cartId}`);
      },
      onInvalidInput: function (errors) {
        errors.forEach(function (error) {
          console.log(error.msg);
        });
      },
    };

    setLoading(false);
    const modal = window.FlywirePayment.initiate(config);
    modal.render();
  };

  const updatePaymentAuthenticatedUserHandler = async () => {
    const updatePayment = await flywireUpdatePaymentAuthUser(token, selectedPayment);

    setLoading(false);
    if (
      updatePayment.status === 200 &&
      updatePayment.data.status === "PAYMENT_HAS_NOT_BEEN_CHANGED"
    ) {
      window.scrollTo(0, 0)
    } else if (
      updatePayment.status === 200 &&
      updatePayment.data.status === "SUCCESS"
    ) {
      history.push(`/`);
    }
  }

  const addPaymentMethodHandler = async () => {
    setLoading(true);

    const { firstName, lastName } = await extractFirstAndLastName(
      bookings[0].customer.name
    );
    const zipCodeCheck = await getLocationByAddress(
      bookings[0].customer.postal_code,
      bookings[0].customer.country_code
    );

    const config = {
      env: process.env.REACT_APP_FLYWIRE_ENV,
      recipientCode: process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,

      // >0 for Seed Payment and Optional Tokenization; 0 for Zero-Value Tokenization;
      amount: 0,
      //Mandatory tokenization before payment - "tokenization" or "optional_tokenization";
      recurringType: "tokenization",
      recurringTypeRecipientCodes: [
        process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,
        process.env.REACT_APP_FLYWIRE_JPT_RECIPIENT_CODE,
      ],

      // Specify the returnUrl or an onCompleteCallback handler
      // returnUrl: process.env.REACT_APP_URL + "/PaymentSuccess/"+ cart_id,

      // Other checkout parameters (e.g. pass payer info or set requestPayerInfo to true)
      firstName: firstName,
      lastName: lastName,
      email: bookings[0].customer.email,
      phone: bookings[0].customer.prefix_number + " " + bookings[0].customer.phone,
      address: bookings[0].customer.address,
      city: zipCodeCheck.address_components.filter(
        (item) =>
          item.types.includes("administrative_area_level_2") ||
          item.types.includes("locality")
      )[0].long_name,
      zip: bookings[0].customer.postal_code,
      country: bookings[0].customer.country_code,

      recipientFields: {
        booking_reference: `CB-${cartId}`,
        additional_information: null,
      },

      requestPayerInfo: false,
      requestRecipientInfo: false,

      // Control which tokenization options are available
      paymentOptionsConfig: {
        filters: {
          type: ["credit_card"],
        },
      },
      onCompleteCallback: function (args) {
        callbackFlywireAddPaymentMethod(args, `CB-${cartId}`);
      },
      onInvalidInput: function (errors) {
        errors.forEach(function (error) {
          console.log(error.msg);
        });
      },
    };

    setLoading(false);
    const modal = window.FlywirePayment.initiate(config);
    modal.render();
  };

  const setDefaultPaymentHandler = async (paymentId) => {
    const updatePayment = await setDefaultPayment(token, paymentId);

    setLoading(false);
    if (
      updatePayment.status === 200
    ) {
      setBookings(updatePayment.data);
    }
  }

  const deletePaymentHandler = async (paymentId) => {
    const updatePayment = await deletePayment(token, paymentId);

    setLoading(false);
    if (
      updatePayment.status === 200
    ) {
      setBookings(updatePayment.data);
    }
  }

  const getCardTypeByBrand = (type) => {
    if (type === "VISA") {
      return visa_icon;
    } else if (type === "MASTERCARD") {
      return master_card_icon;
    } else if (type === "AMERICAN EXPRESS") {
      return american_ex_icon;
    } else if (type === "JCB") {
      return jcb_icon;
    }
    return "Unknown";
  };

  const handleGetBookingsByTokenCartID = async (token) => {
    try {
      setError("");
      setLoading(true);
      const response = await getBookingsByTokenCartID(token);
      if (response.length === 0) {
        setError("No booking found for this cart");
      } else if (!response[0].hasOwnProperty("flywireResponse")) {
        setError("No flywire payment method found for this");
      // } else if (response[0].paymentStatus !== "CHARGE_PENDING") {
      //   setError("Page unavailable");
      } else if (response[0].paymentMethodStatus === "CHANGED") {
        setError("Page unavailable");
      } else {
        setCartId(response[0].cartID);
        setBookings(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingsByTokenCartID(token);
  }, [token]);


  useEffect(() => {
    try {
      const localStorageLang = localStorage.getItem("lang")
      if (!localStorageLang) {
        var userLang = navigator.language || navigator.userLanguage;
        if (userLang === "ja") {
          langChange("JP");
          moment.locale('ja');
        } else {
          langChange("EN");
          moment.locale('en');
        }
      }else{
        langChange(localStorage.getItem("lang"));
      }
    } catch { 
      console.log("error")
    }
  }, []);

   const langChange = (lng) => {
    if(lng === "JP"){
      moment.locale('ja');
    }else{
      moment.locale('en');
    }
    i18n.changeLanguage(lng.toLowerCase());
    localStorage.setItem("lang", lng);
    dispatch(setLanguage(lng));
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <h1>{error}</h1>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: "#F6F6F6" }}>
      {/* Navbar */}
      <nav className="navbar navbar-expand-sm bg-dark navbar-dark sticky-top">
          <div className="container-fluid">
            <div className="d-block d-md-none"></div>
            <img
              alt=""
              src={logo_brand}
              style={{ width: "70px", marginLeft: "100px" }}
              className="navbar-brand d-block d-md-none"
            />
            <img
              alt=""
              src={logo_brand_name}
              style={{ width: "70px" }}
              className="navbar-brand d-none d-md-block"
            />
            <LanguageCard 
              langChangeCallback={langChange}
            />
          </div>
        </nav>
      <div className="container mt-5">
        <p className="fs-22 text-dark fw-700 noto">
          {t("ChangePaymentMethod.Title")}
        </p>
        <div className="bg-white rounded mt-3 p-3">
          <p
            className="text-4E4E4E"
            dangerouslySetInnerHTML={{
              __html: t("ChangePaymentMethod.Desc"),
            }}
          ></p>
          <p className="text-4E4E4E">{t("ChangePaymentMethod.Desc2")}</p>
          <div className="registered-card mb-3">
            <p className="mt-3 fw-700 noto-sans">
              {t("ChangePaymentMethod.RegisteredCard")}
            </p>

            {bookings[0].customer.userId && (
              <>
                <div className="rounded border border-1 border-color-EB001B p-2 mb-3 pointer">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2 py-1">
                      <img
                        src={selectedPayment === "" ? red_radio_button : un_selected_radio_button}
                        alt=""
                        srcSet=""
                        className="ms-2"
                      />
                      <img
                        src={getCardTypeByBrand(
                          bookings[0].flywireResponse.brand
                        )}
                        alt=""
                        srcSet=""
                        height={13}
                      />
                      <p className="m-0">
                        •••• {bookings[0].flywireResponse.digits}, Exp.:{" "}
                        {bookings[0].flywireResponse.expirationMonth}/
                        {bookings[0].flywireResponse.expirationYear}
                      </p>
                      {
                        bookings[0].customer.userId.individualDetail.paymentMethod.find(item => item.isActive).token === bookings[0].flywireResponse.token && (
                          <p className="ms-2 bg-gold rounded text-white m-0 fs-10 px-2 py-1">
                            Default
                          </p>
                        )
                      }
                    </div>
                    <div className="dropdown">
                      <button
                        className=" dropdown-toggle no-dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={three_dots}
                          alt=""
                          srcSet=""
                          className="me-2"
                        />
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            {t("ChangePaymentMethod.SetAsDefault")}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            {t("ChangePaymentMethod.DeleteCard")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <p className="mt-1 text-EB001B">
                  {t("ChangePaymentMethod.UnableProceedPayment")}
                </p>

                {
                  bookings[0].customer.userId.individualDetail.paymentMethod.map((v, ind) => {
                    return (v.brand !== bookings[0].flywireResponse.brand || v.cardNumber !== bookings[0].flywireResponse.digits) ? (
                      <div 
                        className="rounded border-bottom-gold p-2 mb-3 pointer"
                        onClick={() => {
                          setSelectedPayment(v.token)
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center gap-2 py-1">
                            <img
                              src={selectedPayment === v.token ? selected_radio_button : un_selected_radio_button}
                              alt=""
                              srcSet=""
                              className="ms-2"
                            />
                            <img
                              src={getCardTypeByBrand(
                                v.brand
                              )}
                              alt=""
                              srcSet=""
                              height={13}
                            />
                            <p className="m-0">
                              •••• {v.cardNumber}, Exp.:{" "}
                              {v.expirationMonth}/
                              {v.expirationYear}
                            </p>
                            {
                              v.isActive && (
                                <p className="ms-2 bg-gold rounded text-white m-0 fs-10 px-2 py-1">
                                  Default
                                </p>
                              )
                            }
                          </div>
                          <div className="dropdown">
                            <button
                              className=" dropdown-toggle no-dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={three_dots}
                                alt=""
                                srcSet=""
                                className="me-2"
                              />
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <a className="dropdown-item"
                                onClick={() => setDefaultPaymentHandler(v._id)}>
                                  {t("ChangePaymentMethod.SetAsDefault")}
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item"
                                onClick={() => deletePaymentHandler(v._id)}>
                                  {t("ChangePaymentMethod.DeleteCard")}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ) : ""
                  })
                }

                <div className="d-grid d-md-block">
                  <button
                    className="border-gold py-3 py-md-3 px-5 text-4E4E4E fw-400 rounded fs-14 noto-sans"
                    onClick={() => addPaymentMethodHandler()}
                  >
                    <div className="d-flex mx-3 justify-content-center">
                      <img src={add_icon_gold} alt="" />
                      <span className="ms-2">
                        {t("ChangePaymentMethod.AddPaymentMethod")}
                      </span>
                    </div>
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="d-grid d-md-block">
            <button
              className="bg-gold-light-100 py-3 py-md-3 px-5 text-4E4E4E fw-400 rounded fs-14 noto-sans"
              onClick={() => {
                if(bookings[0].customer.userId){
                  updatePaymentAuthenticatedUserHandler()
                }else{
                  changePaymentMethodHandler()
                }
              }}
            >
              <span className="px-5">
                {t("ChangePaymentMethod.UpdatePaymentMethod")}
              </span>
            </button>
          </div>
        </div>
        <div className="mt-3">
          <p className="fs-16 text-dark fw-700 noto">
            {t("ChangePaymentMethod.ReservationDetails")}
          </p>
          <div className="mt-3 bg-white rounded">
            <div className="d-flex justify-content-between p-4">
              <p className="text-4E4E4E fw-700 fs-16 noto m-0">Total</p>
              <p className="text-4E4E4E fw-700 fs-16 noto m-0">
                JPY{" "}
                {bookings
                  .reduce((acc, obj) => acc + obj.totalPrice, 0)
                  .toLocaleString()}
              </p>
            </div>
            <hr className="m-0 hr-D9D9D9" />
            {bookings.map((booking, i = 0) => (
              <div className="booking-item">
                <div className="p-4">
                  <div className="row">
                    <div className="col-12 col-md-3">
                      <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                        {t("my_page.bookings_page.booking_item.booking_id")}
                      </p>
                      <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                        #{booking.key}
                      </p>
                    </div>
                    <div className="col-6 col-md-3">
                      <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                        {t("my_page.bookings_page.booking_item.passenger_name")}
                      </p>
                      <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                        {booking?.passengerName}
                      </p>
                    </div>
                    <div className="col-6 col-md-3">
                      <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                        {t(
                          "my_page.bookings_page.booking_item.type_of_transfer"
                        )}
                      </p>
                      <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                        {booking?.bookingType === "byTheHour"
                          ? t("my_page.bookings_page.booking_item.by_the_hour")
                          : booking?.isAirportTransfer
                            ? t(
                              "my_page.bookings_page.booking_item.airport_transfer"
                            )
                            : t(
                              "my_page.bookings_page.booking_item.specific_destination"
                            )}
                      </p>
                    </div>
                  </div>
                  <hr className="m-0 hr-F4F4F4" />
                  <div className="d-none d-md-block">
                    {/* Desktop view */}
                    <div className="row mt-3">
                      <div className="col-6 col-md-3">
                        <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                          {t("my_page.bookings_page.booking_item.pickup_time")}
                        </p>
                        <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                          {moment(booking?.travelDate).format(
                            "MMM. Do, YYYY (ddd)"
                          )}
                          , {booking.pickupTime}
                        </p>
                      </div>
                      <div className="col-6 col-md-9">
                        <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                          {t(
                            "my_page.bookings_page.booking_item.pick_up_location"
                          )}
                        </p>
                        <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                          {booking.pickupLocation?.label}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-3">
                        <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                          {t(
                            "my_page.bookings_page.booking_item.estimated_drop_off_time"
                          )}
                        </p>
                        <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                          {moment(booking?.customerDropoffDateTime).format(
                            "MMM. Do, YYYY (ddd), HH:mm"
                          )}
                        </p>
                      </div>
                      <div className="col-6 col-md-9">
                        <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                          {t(
                            "my_page.bookings_page.booking_item.drop_off_location"
                          )}
                        </p>
                        <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                          {booking?.destinations.map((item, index) => {
                            return (
                              <>
                                {item.label}
                                {index === booking.destinations.length - 1 ? (
                                  ""
                                ) : (
                                  <br />
                                )}
                              </>
                            );
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-block d-md-none">
                    {/* Mobile view */}
                    <div className="row mt-3">
                      <div className="col-6 col-md-3">
                        <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                          {t("my_page.bookings_page.booking_item.pickup_time")}
                        </p>
                        <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                          {moment(booking?.travelDate).format(
                            "MMM. Do, YYYY (ddd)"
                          )}
                          , {booking.pickupTime}
                        </p>
                      </div>
                      <div className="col-6 col-md-3">
                        <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                          {t(
                            "my_page.bookings_page.booking_item.estimated_drop_off_time"
                          )}
                        </p>
                        <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                          {moment(booking?.customerDropoffDateTime).format(
                            "MMM. Do, YYYY (ddd), HH:mm"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-3">
                        <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                          {t(
                            "my_page.bookings_page.booking_item.pick_up_location"
                          )}
                        </p>
                        <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                          {booking.pickupLocation?.label}
                        </p>
                      </div>
                      <div className="col-12 col-md-3">
                        <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                          {t(
                            "my_page.bookings_page.booking_item.drop_off_location"
                          )}
                        </p>
                        <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                          {booking?.destinations.map((item, index) => {
                            return (
                              <>
                                {item.label}
                                {index === booking.destinations.length - 1 ? (
                                  ""
                                ) : (
                                  <br />
                                )}
                              </>
                            );
                          })}
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr className="mt-3 hr-F4F4F4" />
                  <div className="row">
                    <div className="col-12 col-md-3">
                      <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                        {t("Vehicle")}
                      </p>
                      <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                        {booking.bookingInfo.map((item, index) => {
                          return (
                            <>
                              {item.vehicleTypeName}
                              {index === booking.bookingInfo.length - 1
                                ? ""
                                : ", "}
                            </>
                          );
                        })}
                      </p>
                    </div>
                    <div className="col-6 col-md-3">
                      <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                        {t("my_page.bookings_page.booking_item.passenger")}
                      </p>
                      <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                        {booking.bookingInfo.map((item, index) => {
                          return item.pax
                            .filter((passenger) => passenger.paxcount > 0)
                            .map((passenger, index, array) => (
                              <span key={passenger.paxtype}>
                                {t(
                                  `my_page.bookings_page.booking_item.${passenger.paxtype}`
                                )}
                                : {passenger.paxcount}
                                {index !== array.length - 1 ? ", " : ""}
                              </span>
                            ));
                        })}
                      </p>
                    </div>
                    <div className="col-6 col-md-3">
                      <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                        {t("my_page.bookings_page.booking_item.luggage")}
                      </p>
                      <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                        {booking.bookingInfo.map((item, index) => {
                          return item.luggage
                            .filter((luggage) => luggage.luggagecount > 0)
                            .map((luggage, index, array) => (
                              <span key={luggage.luggagetype}>
                                {luggage.luggagetype === "CHECK-IN SIZE"
                                  ? t(
                                    "my_page.bookings_page.booking_item.check_in"
                                  )
                                  : t(
                                    "my_page.bookings_page.booking_item.cabin"
                                  )}
                                : {luggage.luggagecount}
                                {index !== array.length - 1 ? ", " : ""}
                              </span>
                            ));
                        })}
                      </p>
                    </div>
                  </div>
                  <hr className="mt-3 hr-F4F4F4" />
                  <div className="row">
                    <div className="col-12 col-md-3">
                      <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                        {t("my_page.bookings_page.booking_item.add_on_service")}
                      </p>
                      {booking.meetAssist != null &&
                        (booking?.meetAssist[0].meetAssistName !== "none" ||
                          booking?.meetAssist[1].meetAssistName !== "none") && (
                          <div>
                            <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                              {t(
                                "my_page.bookings_page.booking_item.airport_meet_assist"
                              ) +
                                "(" +
                                (booking?.meetAssist[0].meetAssistName !==
                                  "none" &&
                                  booking?.meetAssist[1].meetAssistName !== "none"
                                  ? t("my_page.bookings_page.booking_item.both")
                                  : "ok") +
                                ")"}
                            </p>
                            <br />
                          </div>
                        )}
                      {booking.bookingInfo[0].travelArea &&
                        booking.bookingType === "byTheHour" &&
                        booking.bookingInfo[0].travelArea !== "" && (
                          <div>
                            <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                              {`${t(
                                "NewCart.AddOnServices.TravelArea.Title"
                              )} ${t(
                                "NewCart.AddOnServices.TravelArea." +
                                booking.bookingInfo[0].travelArea
                              )}`}
                            </p>
                            <br />
                          </div>
                        )}
                      {booking.childSeatPrice > 0 ? (
                        <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                          {t("my_page.bookings_page.booking_item.child_seat")} x{" "}
                          {booking.bookingInfo.reduce(
                            (sum, booking) =>
                              sum +
                              booking.infantSizeSeat +
                              booking.toddlerSizeSeat +
                              booking.childSizeSeat,
                            0
                          )}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-12 col-md-3">
                      <p className="fw-700 fs-11 noto-sans text-A7A7A7 mb-1">
                        {t(
                          "my_page.bookings_page.booking_item.additional_information"
                        )}
                      </p>
                      {booking?.pickupFlightNo == null &&
                        booking?.dropoffFlightNo == null && (
                          <p className="to-be-assigned">
                            {t("my_page.bookings_page.booking_item.none")}
                          </p>
                        )}
                      {booking?.pickupFlightNo !== null && (
                        <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                          {t(
                            "my_page.bookings_page.booking_item.arrival_flight_number"
                          )}
                          {booking?.pickupFlightNo}
                          <br />{" "}
                        </p>
                      )}
                      {booking?.dropoffFlightNo !== null && (
                        <p className="fw-700 fs-14 noto-sans text-4E4E4E">
                          {t(
                            "my_page.bookings_page.booking_item.depature_flight_number"
                          )}
                          {booking?.dropoffFlightNo}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gold-light-100 border-bottom-DBBE78">
                  <div className="py-3 px-4">
                    <div className="d-flex justify-content-between">
                      <p className="m-0 fw-400 fs-14 noto-sans text-282828">
                        {t("my_page.bookings_page.booking_item.price")}
                      </p>
                      <p className="m-0 fw-700 fs-14 noto-sans text-282828">
                        JPY {booking.totalPrice.toLocaleString()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="pb-5"></div>
    </div>
  );
};

export default ChangePaymentMethod;
