import {
  ADD_CANCEL_RESCHEDULE_REQUEST,
  CANCEL_RESCHEDULE_REQUEST_ERROR,
  GET_CANCEL_RESCHEDULE_REQUESTS,
  GET_CANCEL_RESCHEDULE_REQUEST,
  ADD_RESPONSE_CANCEL_RESCHEDULE_REQUEST,
} from "../types";
// import axios from "axios";
import customAxios from "../../utils/customAxios";
import setAuthToken from "../../utils/setAuthToken";
import { setAlert } from "../alert";

// get vehicle by id
export const getCancelRescheduleRequestById = (id) => async (dispatch) => {
  try {
    const res = await customAxios.get(`/api/request-change-booking/${id}`);
    dispatch({
      type: GET_CANCEL_RESCHEDULE_REQUEST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CANCEL_RESCHEDULE_REQUEST_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// get vehicles
export const getCancelRescheduleRequests = () => async (dispatch) => {
  try {
    const res = await customAxios.get("/api/request-change-booking");
    dispatch({
      type: GET_CANCEL_RESCHEDULE_REQUESTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CANCEL_RESCHEDULE_REQUEST_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

//admin response request
export const addResponseRequest = (formData) => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await customAxios.post("/api/request-change-booking/admin-response", formData, config);
    dispatch({
      type: ADD_RESPONSE_CANCEL_RESCHEDULE_REQUEST,
      payload: res.data,
    });
    dispatch(
      setAlert("Successfully send email to customer")
    );
  } catch (err) {
    dispatch({
      type: CANCEL_RESCHEDULE_REQUEST_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};